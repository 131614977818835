import { IsActiveMatchOptions } from "@angular/router";
import { RolesInterface } from "app/modules/admin/usuarios/perfil/perfil.types";

export interface FuseNavigationItem {
    id?: string;
    title?: string;
    subtitle?: string;
    type: "aside" | "basic" | "collapsable" | "divider" | "group" | "spacer";
    hidden?: (item: FuseNavigationItem) => boolean;
    active?: boolean;
    disabled?: boolean;
    tooltip?: string;
    link?: string;
    externalLink?: boolean;
    target?: "_blank" | "_self" | "_parent" | "_top" | string;
    exactMatch?: boolean;
    isActiveMatchOptions?: IsActiveMatchOptions;
    function?: (item: FuseNavigationItem) => void;
    classes?: {
        title?: string;
        subtitle?: string;
        icon?: string;
        wrapper?: string;
    };
    icon?: string;
    badge?: {
        title?: string;
        classes?: string;
    };
    children?: FuseNavigationItem[];
    meta?: any;
    role?: Partial<RolesInterface>;
}

export type FuseVerticalNavigationAppearance = "default" | "compact" | "dense" | "thin";

export type FuseVerticalNavigationMode = "over" | "side";

export type FuseVerticalNavigationPosition = "left" | "right";
